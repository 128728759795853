
.parent {
  font-family: 'Raleway', sans-serif;
  height: 100%;
  text-align: center;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 9vh 4fr 12vh;
  grid-column-gap: 0;
  grid-row-gap: 0;
  justify-items: stretch;
  min-height: 100vh;
}

.header { grid-area: 1 / 1 / 2 / 2; }
.profile { grid-area: 2 / 1 / 3 / 2; }
.footer { grid-area: 3 / 1 / 4 / 2; }

/* Put media queries here */
@media only screen
and (max-device-width : 321px)
and (max-width: 320px)
and (orientation : portrait) {
  .parent {
    grid-template-rows: 64px 1fr 14vh;
  }
  .profile {
    margin-top: 0;
  }
}

@media only screen
and (max-device-width : 480px)
and (max-width: 480px)
and (orientation : landscape) {
  .parent {
    grid-template-rows: 9vh 1fr 12vh;
  }
}

.goBack {
  color: #00cded;
  position: absolute;
  top: 2rem;
  left: 2rem;
}


/* .rbc-row-content.rbc-row-content.rbc-row-content {
  position: relative;
  user-select: auto;
  -webkit-user-select: auto;
  z-index: 4;
} */