/* Sweetalert custom styles */
.swal2-popup.mh-modal {
  border-radius: 10px;
  width: 460px;
}

.swal2-popup.mh-modal .swal2-styled.swal2-confirm {
  border-radius: 6px;
  background-color: #fff;
  color: #00cded;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 6px;
  box-shadow: none;
}

.swal2-popup.mh-modal .swal2-styled.swal2-cancel {
  border: 1px solid #00cded;
  border-radius: 6px;
  background-color: #00cded;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 700;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
}

.swal2-popup.mh-modal .swal2-validation-message {
  background: rgba(0, 205, 237, 0.2);
  color: #f27474;
  font-size: 15px;
  font-weight: 900;

}

.mh-modal .swal2-title {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
}
.mh-modal .swal2-icon.swal2-info {
  border-color: #00cded;
  color: #00cded;
}
.mh-modal .swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid #00cded;
}
.mh-modal .swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #00cded;
}
.swal2-popup.mh-modal .swal2-close {
  color: #0b0b0b;
  font-family: "Raleway", arial, sans-serif;
  font-weight: 100;
}
.swal2-popup.swal-reject .swal2-close {
  color: #0b0b0b;
  font-family: "Raleway", arial, sans-serif;
  font-weight: 100;
}

.swal2-popup.mh-modal .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none;
}
