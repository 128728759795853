.header {
  border-bottom: 1px solid #00cded;
  height: 64px;
  overflow: hidden;
  width: 100%;
  display: flex;

  .logo {
    float: left;
    height: 33px;
    padding-top: 16px;
    padding-left: 19px;
    padding-bottom: 16px;
    width: 202px;
  }
}
