.bg-mh {
  background-color: #fff;
  margin-top: 20px;

  .paginating-button {
    background-color: #fff;
    border: 2px solid #00cded;
    border-radius: 4px;
    font-family: Raleway, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 2px;
    padding: 4px 8px 6px 8px;

    &:focus {
      outline: none;
    }
  }
}

.task-filter {
  color: #00cded;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  top: -24px;
  right: -125px;
  position: relative;
}

.filter-section {
  //border: 3px dashed #00cded;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding: 12px 2px;
  width: 88%;

  .filter-by-id {
    display: table;
    font-family: Raleway, sans-serif;
    font-size: 18px;
    margin: 8px 4px;

    label {
      display: table-cell;
      text-align: left;
      width: 164px;
    }

    input {
      border: 1px solid #ccc;
      border-radius: 6px;
      display: table-cell;
      font-size: 18px;
      font-weight: 500;
      padding-left: 6px;
      text-align: left;
      height: 32px;
      width: 150px;

      &:focus {
        outline: none;
      }
    }
  }

  .filter-by-stage {
    display: table;
    font-family: Raleway, sans-serif;
    font-size: 18px;
    margin: 8px 4px;

    label {

      display: table-cell;
      text-align: left;
      width: 164px;
    }

    .stage-select-container {
      display: table-cell;
      font-size: 16px;
      width: 156px;

      &:focus {
        outline: none;
      }

      .stage-select__control {
        border-radius: 6px;

        .stage-select__value-container {

        }
        .stage-select__indicators {

          .stage-select__indicator-separator {
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }

    }
  }

  .filter-by-date {
    display: table;
    font-family: Raleway, sans-serif;
    font-size: 18px;
    margin: 8px 4px;

    label {
      display: table-cell;
      text-align: left;
      width: 164px;
    }

    .date-select-container {
      display: table-cell;
      width: 156px;

      &:focus {
        outline: none;
      }

      .date-select__control {
        border-radius: 6px;

        .date-select__value-container {

        }
        .date-select__indicators {

          .date-select__indicator-separator {
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }

    }
  }

  .reset-filters {
    color: #00cded;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    padding-left: 2px;
    text-align: left;
  }
}